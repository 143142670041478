
<template>
    <div>
        <!--body!-->
        <div id="invoiceholder">

            <div id="headerimage" class="headline" :style="{ backgroundImage: 'url(' + require('@/assets/images/slider.jpg') + ')' }"></div>
            <div id="invoice" class="effect2">
                <div id="invoice-top">
                    <h1 class="center mb5">{{$t("myOrder")}}</h1>
                    <div class="title mobileHide">
                        <p>{{$t("today")}}: {{today}}
                        </p>
                    </div>
                </div>


                
                <div id="invoice-mid">
                
                    <div class="clientlogo"></div>
                   
                    <div class="info">
                        {{$t("paymentInfo")}}
                        <ul>HSBC
                            <li><b>143141562001</b></li>
                        </ul>
                        <ul>FPS
                            <li><b>161224431</b></li>
                        </ul>
                    </div>
                    <div class="info">
                        <h2>{{login_info_detail.user}}</h2>
                        <p>{{login_info_detail.email}}</p>
                        {{login_info_detail.user_id}}
                    </div>

                    <div id="project">
                        <h2>{{$t("reminder")}}</h2>
                        <p>{{$t("reminderMsg")}}</p>
                    </div> 
                </div><!--End Invoice Mid-->
                
                <!-- pc端訂單列表 -->
                <div id="invoice-bot" class="pcTable">
                    <div id="table">
                        <table>
                        <tr class="tabletitle">
                            <!-- <td class="item"><h2>訂單參考號</h2></td> -->
                            <td class="Hours"><h2>{{$t("bookingNum")}}</h2></td>
                            <td class="Hours"><h2>{{$t("bookingDate")}} {{$t("bookingTime")}}</h2></td>
                            <td class="Rate"><h2>{{$t("bookingAddress")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("serviceNme")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("price")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("overtime")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("phone")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("email")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("TheEngineNumber")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("shippingName")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("shippingLength")}}</h2></td>
                            <td class="Hours"><h2>{{$t("remark")}}</h2></td>
                            <td class="Hours"><h2>{{$t("orderStatus")}}</h2></td>
                            <td class="Hours"><h2>{{$t("action")}}</h2></td>
                        </tr>
                        <tr>
                            <td colspan="7" style="padding: 1vw 0;text-align:center;" v-if="!load && listOrder.length == 0">{{$t("noData")}}</td>
                            <td colspan="7" style="padding: 1vw 0;text-align:center;" v-if="load">
                               {{$t("loading")}}
                            </td>
                        </tr>
                        <tr v-for="(value,i) in listOrder" :key="i" class="service">
                            <!-- <td class="tableitem"><p class="itemtext">{{value.reference_code}}</p></td> -->
                            <td class="tableitem"><p class="itemtext">{{value.order_num}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.booking_date}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.address}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.service_name}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.price}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.overtime}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.phone}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.email}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.engine_qty}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.yacht_name}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.yacht_len}}</p></td>
                            <td class="tableitem"><p class="itemtext">{{value.remark}}</p></td>
                            <td class="tableitem"><p  class="itemtext" v-html="value.order_status"></p></td>
                            <td class="tableitem ">
                                <!-- <div class="primary op Btn" @click="edit(value)">修改時間</div> -->
                                <div class="error op Btn" 
                                    v-if="value.status != 3"
                                    @click="cancel(value)">{{$t('cancel')}}</div>
                            </td>

                        </tr>
                        
                        
                        <tr class="tabletitle">
                            <td class="Hours"><h2>{{$t("bookingNum")}}</h2></td>
                            <td class="Hours"><h2>{{$t("bookingDate")}}</h2></td>
                            <td class="Rate"><h2>{{$t("bookingAddress")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("serviceNme")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("price")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("overtime")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("phone")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("email")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("TheEngineNumber")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("shippingName")}}</h2></td>
                            <td class="subtotal"><h2>{{$t("shippingLength")}}</h2></td>
                            <td class="Hours"><h2>{{$t("remark")}}</h2></td>
                            <td class="Hours"><h2>{{$t("orderStatus")}}</h2></td>
                            <td class="Hours"><h2>{{$t("action")}}</h2></td>
                        </tr>

                        
                        </table>
                    </div><!--End Table-->
                
                    <form>
                        <!--<input type="hidden" name="cmd" value="_s-xclick">!-->
                        <input type="hidden" name="hosted_button_id" value="QRZ7QTM9XRPJ6">
                        <!-- <button @click="" class="btn btn-outline-danger btn-contact">更改預約時間/取消預約</button> -->
                    </form>

                
                    <div id="legalcopy">
                        <p class="legal">
                            {{$t('thankF')}}
                            
                        </p>
                    </div>
                
                </div>

                <!-- 手機端訂單列表 -->
                <div class="listWrap">
                    <h5 v-if="listOrder.length == 0" class="ju">{{$t("noData")}}</h5>
                    <div class="listItem" v-for="(item,i) in listOrder" :key="i">
                        <div class="listtitle"
                            data-toggle="collapse" 
                            :data-target="'#item'+i">{{item.service_name}} ({{item.booking_date}})</div>
                        <div :id="'item'+i" :class="['collapse', 'listC', { show: i==0 }]">
                            <div class="al">
                                <div class="moLable">{{$t("bookingNum")}}: </div>
                                <div >{{item.order_num}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("bookingData")}}: </div>
                                <div >{{item.booking_date}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("shippingLocation")}}: </div>
                                <div >{{item.address}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("serviceNme")}}: </div>
                                <div >{{item.service_name}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("price")}}: </div>
                                <div >{{item.price}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("overtime")}}: </div>
                                <div >{{item.overtime}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("TheEngineNumber")}}: </div>
                                <div >{{item.engine_qty}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("shippingName")}}: </div>
                                <div >{{item.yacht_name}}</div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("shippingLength")}}: </div>
                                <div >{{item.yacht_len}}</div>
                            </div>

                            <div class="al">
                                <div class="moLable">{{$t("orderStatus")}}: </div>
                                <div v-html="item.order_status" class="ju al" style="width: 50%;"></div>
                            </div>
                            <div class="al">
                                <div class="moLable">{{$t("remark")}}: </div>
                                <div v-html="item.remark" style="width: 60%;"></div>
                            </div>
                            <br>
                            <div class="sb">
                                <div></div>
                                <div class="error op Btn" 
                                    v-if="item.status != 3"
                                    @click="cancel(item)">{{$t("cancel")}}</div>
                            </div>

                        </div>
                    </div>
                    
                </div>

            </div>
        </div>

        
    </div>
</template>



<script>
export default {
    name: 'userPanel',
    data () {
        return {
            listOrder: [],
            login_info_detail: {},
            today: "",
            detail:{},
            load: false,
            lang: ""
        }
    },
    watch: {
        "$i18n.locale": {
            handler (val) {
                this.getData()
                console.log(val)
                if (val == "zh-US") {
                    this.lang = "_en"
                } else {
                    this.lang = ""
                }
            },
            immediate:true
        }
    },
    methods:{
        edit (item) {
            this.detail = JSON.parse(JSON.stringify(item))
        },
        sub () {
            this.$http({
                url: "order",
                method:"PUT",
                data: {
                    id: this.detail.id,
                    booking_date: this.detail.booking_date
                }
            }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    this.getData()
                }
            })
        },
        cancel (item) {
            let that = this
            that.detail = item
            this.$Swal.fire({
                title: this.$t('attention'),
                text:this.$t('sureCancel'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel')
            }).then(res => {
                console.log(res)
                if (res.value) {
                    that.$http({
                        url: "order",
                        method:"DELETE",
                        data: {
                            id: that.detail.id
                        }
                    }).then(res => {
                        console.log(res)
                        // document.getElementsByClassName("close")[0].click()
                        that.getData()
                    })
                }
            })
        },
        getData () {
            this.load = true
            this.$http.get("order").then(res => {
                console.log(res)
                this.load = false
                if (res.data) {
                    res.data.forEach(order => {
                        order.service_name = order.service==1? this.$t('captain') : order.service==2 ? this.$t('sail') : "loading..."
                        if (order.type == "2") {
                            if (this.lang == "") {
                                order.service_name = order.captain + ' ' + order.sailor
                            } else if (this.lang == "_en") {
                                order.service_name = 
                                                    (order.captain.replace("船長","Captain:").replace("人",",") )
                                                    + (order.sailor.replace("水手","Sailor: ").replace("人",""))
                            }
                            
                            let price = 0
                            if (order.captain) {
                                let num = order.captain.match(/\d+/g)
                                if (num) {
                                    price += num[0] * 1600
                                    price += 450 * order.overtime
                                }
                            }
                            if (order.sailor) {
                                console.log(order.sailor)
                                let num = order.sailor.match(/\d+/g)
                                if (num) {
                                    price += num[0] * 1100
                                    price += 300 * order.overtime
                                }
                                
                            }
                            order.price = "HK$ " +  price
                        }
                        order.status = order.order_status
                        if (order.order_status == 1) {
                            order.order_status = `<span class="statusTag yellowBg">${this.$t('noPay')}</span>`
                        } else if (order.order_status == 2) {
                            order.order_status = `<span class="statusTag greenBg">${this.$t('paid')}</span>`
                        } else if (order.order_status == 3) {
                            order.order_status =`<span class="statusTag redBg">${this.$t('canceled')}</span>`
                        }
                        
                    })
                    this.listOrder = res.data.reverse()

                    this.$http({
                        url: "service",
                        method:"GET"
                    }).then(s => {
                        console.log(s)
                        if (s.data.code == 200) {
                            this.listOrder.forEach(order => {
                                s.data.data.forEach(se => {
                                    if (order.service_id == se.id) {
                                        order.service_name=se['name' + this.lang]
                                        order.price = "HK$ " + order.booking_Period * se.price
                                    }
                                })
                                
                            })
                            this.listOrder = [...this.listOrder]
                        }
                    })
                } else {
                    this.listOrder = []
                    this.load = false
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    mounted() {
        this.getData()
        let currentDate = new Date();
        this.today= currentDate.toLocaleDateString()

        // this.$http.get('http://127.0.0.1:5000/order/user?userId='+ this.$route.query.id).then((response) => {
        //     console.log(response.data)
        //     this.listOrder = response.data
        // }).catch(error => console.log(error))
        
    }
}


        

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,900,700,500,300,100);
* {
  margin: 0;
  box-sizing: border-box;
}

.headline{
    border-top: solid 10px darkblue;
}

::selection {
  background: #f31544;
  color: #fff;
}
::moz-selection {
  background: #f31544;
  color: #fff;
}
h1 {
  font-size: 1.5em;
  color: #222;
}
h2 {
  font-size: 0.9em;
}
h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
p {
  font-size: 0.7em;
  color: #666;
  line-height: 1.2em;
}

#invoiceholder {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  background: #e0e0e0;
  /* z-index: -999; */
  /* position: relative; */
}
#headerimage {
  /* z-index: -1; */
  position: relative;
  top: -50px;
  height: 350px;
  

  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    inset 0 -2px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    inset 0 -2px 4px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    inset 0 -2px 4px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  background-size: 100%;
  background-position: 50% -90%;
}
#invoice {
  position: relative;
  top: -290px;
  margin: 0 auto;
  width: 90%;
  background: #fff;
}

[id*="invoice-"] {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #eee;
  padding: 30px;
}

#invoice-top {
  min-height: 120px;
}
#invoice-mid {
  min-height: 150px;
}
#invoice-bot {
  min-height: 250px;
}

.logo {
  float: left;
  height: 60px;
  width: 60px;
  background-size: 60px 60px;
}
.clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}
.info {
  display: block;
  float: left;
  margin-left: 20px;
}
.title {
  float: right;
}
.title p {
  text-align: right;
}
#project {
  margin-left: 52%;
}
table {
  width: 100%;
  border-collapse: collapse;
}
td {
  padding: 5px 0 5px 15px;
  border: 1px solid #eee;
}
.tabletitle {
  padding: 5px;
  background: #eee;
}
.service {
  border: 1px solid #eee;
}
/*.item {
  width: 50%;
}*/
.itemtext {
  font-size: 0.9em;
}

#legalcopy {
  margin-top: 30px;
}
form {
  float: right;
  margin-top: 30px;
  text-align: right;
}

.effect2 {
  position: relative;
}
.effect2:before,
.effect2:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.effect2:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.legal {
  width: 70%;
}
.primary {
    background: #007BFF;color: #FFF;
    display: inline-block;
}
.error {
    background: #E50000;color: #FFF;display: inline-block;
}
.Btn {
    padding: 3px 15px;
    margin: 0 3px;
    cursor: pointer;
    border-radius: 3px;;
}
.centerBox {
    left: 50% ;
    top: 10%;
    transform: translateX(-50%) !important;
}
.width80 {
    width: 80%;
    margin: auto;
}
.width100 {
    width: 70%;
    margin: 20px auto;
}
.listWrap {
    padding: 5%;
}
.listtitle {
    background: #FCFCFC;
    padding: 2px;
}
.listItem {
    border: solid #CCC 1px;
    border-bottom: none;
}
.listItem:last-child {
    border-bottom: solid #CCC 1px !important;
}
.listC {
    border-top: solid #CCC 1px;
    padding: 2px;
}
.moLable {
    width: 40%;
    text-align: right;
    padding-right: 2px; 
}
.listWrap {
    display: none;
}


</style>